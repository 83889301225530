<template>
  <v-card elevation="0" width="100%">
    <v-row dense no-gutters>
      <v-col cols="12" class="mb-2">
        <v-btn
          v-if="$vuetify.breakpoint.xsOnly"
          text
          x-small
          style="vertical-align: middle"
          @click="$emit('backtoUserSetting')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="text-h5 pl-sm-4" style="vertical-align: middle">
          {{ $t("companyInformation") }}
        </span>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" class="my-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12" sm="4">
        <v-card-text class="py-1">
          <div>
            {{ $t("companyName") }}
          </div>
          <p class="text-h6">
            {{ currentCompany.name }}
          </p>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card-text class="py-1">
          <div>
            {{ $t("companyPhoneNumber") }}
          </div>
          <p class="text-h6">
            {{ currentCompany.phone }}
          </p>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card-text class="py-1">
          <div>
            {{ $t("companyEmail") }}
          </div>
          <p class="text-h6">
            {{ currentCompany.email }}
          </p>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card-text class="py-1">
          <div>
            {{ $t("companyAddressText") }}
          </div>
          <p class="text-h6">
            {{ currentCompany.address }}
          </p>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card-text class="py-1">
          <div>
            {{ $t("cityText") }}
          </div>
          <p class="text-h6">
            {{ currentCompany.city }}
          </p>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card-text class="py-1">
          <div>
            {{ $t("stateText") }}
          </div>
          <p class="text-h6">
            {{ currentCompany.state }}
          </p>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card-text class="py-1">
          <div>
            {{ $t("zipText") }}
          </div>
          <p class="text-h6">
            {{ currentCompany.zip }}
          </p>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row dense no-gutters v-if="checkPermission('viewTickets')">
      <v-col cols="12">
        <v-card-text class="py-1">
          <p class="text-h6">
            {{ $t("811Center") }}
          </p>
          <v-divider></v-divider>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row dense no-gutters v-if="checkPermission('viewTickets')">
      <v-col cols="12">
        <v-card-text class="py-1">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("811Center") }}
                  </th>
                  <th class="text-left">
                    {{ $t("excavatorType") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="center in currentCompany.centers"
                  :key="center.center_id"
                >
                  <td>
                    {{ center.center_name }}
                  </td>
                  <td>{{ center.excavator_type }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import UserDataMixin from "@/mixins/UserData";
export default {
  props: {
    companydata: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [UserDataMixin],
  data() {
    return {
      currentCompany: {},
    };
  },
  mounted() {
    this.currentCompany = this.companydata;
  },
};
</script>

<style scoped>
.v-text-field[readonly="readonly"] {
  border: none !important;
  border-color: transparent !important;
}
</style>
