var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "0", width: "100%" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mb-2", attrs: { cols: "12" } },
            [
              _vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { "vertical-align": "middle" },
                      attrs: { text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("backtoUserSetting")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "text-h5 pl-sm-4",
                  staticStyle: { "vertical-align": "middle" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("companyInformation")) + " ")]
              ),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.xsOnly
            ? _c(
                "v-col",
                { staticClass: "my-1", attrs: { cols: "12" } },
                [_c("v-divider")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-card-text", { staticClass: "py-1" }, [
                _c("div", [_vm._v(" " + _vm._s(_vm.$t("companyName")) + " ")]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.currentCompany.name) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-card-text", { staticClass: "py-1" }, [
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.$t("companyPhoneNumber")) + " "),
                ]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.currentCompany.phone) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-card-text", { staticClass: "py-1" }, [
                _c("div", [_vm._v(" " + _vm._s(_vm.$t("companyEmail")) + " ")]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.currentCompany.email) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-card-text", { staticClass: "py-1" }, [
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.$t("companyAddressText")) + " "),
                ]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.currentCompany.address) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-card-text", { staticClass: "py-1" }, [
                _c("div", [_vm._v(" " + _vm._s(_vm.$t("cityText")) + " ")]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.currentCompany.city) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-card-text", { staticClass: "py-1" }, [
                _c("div", [_vm._v(" " + _vm._s(_vm.$t("stateText")) + " ")]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.currentCompany.state) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-card-text", { staticClass: "py-1" }, [
                _c("div", [_vm._v(" " + _vm._s(_vm.$t("zipText")) + " ")]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.currentCompany.zip) + " "),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.checkPermission("viewTickets")
        ? _c(
            "v-row",
            { attrs: { dense: "", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "py-1" },
                    [
                      _c("p", { staticClass: "text-h6" }, [
                        _vm._v(" " + _vm._s(_vm.$t("811Center")) + " "),
                      ]),
                      _c("v-divider"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.checkPermission("viewTickets")
        ? _c(
            "v-row",
            { attrs: { dense: "", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "py-1" },
                    [
                      _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("811Center")) +
                                            " "
                                        ),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("excavatorType")) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.currentCompany.centers,
                                      function (center) {
                                        return _c(
                                          "tr",
                                          { key: center.center_id },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(center.center_name) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(center.excavator_type)
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3032191863
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }